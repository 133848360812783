import Utility from "../../../shared/utility";
import AddFavourite from "../../utility/js/addFav";
import EncryptUtility from "../../utility/js/encrypt-utility";
import breadcrumbComp from "../../common/breadcrumb-comp";

import { Line as LineChartGenerator, Doughnut,Bar } from "vue-chartjs/legacy";
import { Chart as ChartJS, Title, Tooltip, Legend,BarElement, LineElement, CategoryScale, LinearScale, PointElement, ArcElement } from "chart.js";

ChartJS.register(Title, Tooltip, Legend, LineElement, CategoryScale, LinearScale, PointElement,BarElement, ArcElement);

export default {
  data() {
    return {
      userId: EncryptUtility.localStorageDecrypt("userID"),
      mainPage: null,
      pageUrl: null,
      subPage: null,
      showAdd: false,
      backSlash: true,
      tiles: [],
      lineData: [],
      donutData: [],
      lineLoaded: false,
      donutLoaded: false,
      lineXValues: [],
      lineYValues: [],
      dateRangesLine: [
        { name: "Last 7 Days", value: 7 },
        { name: "Last 14 Days", value: 14 },
        { name: "Last 30 Days", value: 30 },
        { name: "Last 60 Days", value: 60 },
        { name: "Last 90 Days", value: 90 },
      ],
      dateRangesDonut: [
        { name: "Last 7 Days", value: 7 },
        { name: "Last 14 Days", value: 14 },
        { name: "Last 30 Days", value: 30 },
        { name: "Last 60 Days", value: 60 },
        { name: "Last 90 Days", value: 90 },
      ],
      rangeValueLine: 7,
      rangeValueDonut: 7,
      completeTasks: 0,
      openTasks: 0,
      totalTaskAmount: 0,
      tat: 0,
      chartOptionsLine: null,
      chartOptionsBar:null,
      chartDataLine: null,
      chartDataBar: null,
      chartOptionsDonut: null,
      chartDataDonut: null,
      taskPercentage: 0,
      actionList: [],
    };
  },
  async created() {
    this.route = this.$route.path.replace("/", "");
    let data = await AddFavourite.getPageAction(this.userId, this.route);
    // AddFavourite.getPageAction has API call; needs assistance, adding actions to app role
    // this.actionList = data.actionList;
    this.actionList = [];
    this.mainPage = data.mainPage;
    this.pageUrl = data.pageUrl;
    this.subPage = data.subPage;
    this.showAdd = data.showAdd;
    this.actionList = data?.actionList;
  },
  mounted() {
    this.getTiles();
    this.getPulledHardware("line");
    this.getPulledHardware("bar");
    this.getPulledHardware("donut");
    setTimeout(() => {
      this.getTiles();
      this.getPulledHardware("line");    
    this.getPulledHardware("bar");
      this.getPulledHardware("donut");
    }, 60000);
  },
  methods: {
    // Reset the screen
    resetFunction() {
      this.rangeValueLine = 7;
      this.rangeValueDonut = 7;
      this.getTiles();
      this.getPulledHardware("line");
      
    this.getPulledHardware("bar");
      this.getPulledHardware("donut");
    },
    // Get tile info
    getTiles() {
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      this.axios
        .get(`/usb/usb_pulled_hardware_dashboard_tiles?user_key=${this.userId}`)
        .then((response) => {
          if (response.status == 200) {
            let responseData = JSON.parse(response.data.body.message);
            this.tiles = responseData.tiles;
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            var Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          var Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },
    // Get chart data
    getPulledHardware(chartType) {
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      let keyword = "_line";
      if (chartType === "donut") {
        keyword = "_donut";
      }      
      this.axios
        .get(
          `/usb/usb_pulled_hardware_dashboard` +
            keyword +
            `?user_key=${this.userId}` +
            `&ldays=${chartType === "line" ||chartType === "bar" ? this.rangeValueLine : this.rangeValueDonut}`
        )
        .then((response) => {
          if (response.status == 200) {
            let responseData = JSON.parse(response.data.body.message);
            if (chartType === "line") {
              this.lineXValues = [];
              this.lineYValues = [];
              this.lineData = responseData.line_data;
              for (var i = this.lineData.length - 1; i >= 0; i--) {
                this.lineXValues.push(this.lineData[i].date);
                this.lineYValues.push(this.lineData[i].daily_total);
              }
              this.chartDataLine = {
                labels: this.lineXValues,
                datasets: [
                  {
                    label: "New Tasks",
                    data: this.lineYValues,
                    fill: false,
                    borderColor: this.$vuetify.theme.isDark ? "white" : "black",
                    borderWidth: 2,
                    pointBackgroundColor: "white",
                    radius: 5,
                  },
                ],
              };
              this.chartOptionsLine = {
                responsive: true,
                maintainAspectRatio: false,
                title: {
                  text: "",
                  fontColor: this.$vuetify.theme.isDark ? "white" : "black",
                  display: true,
                  fontSize: 25,
                },
                legend: {
                  labels: {
                    fontColor: this.$vuetify.theme.isDark ? "white" : "black",
                  },
                },
                tension: 0.4,
                scales: {
                  y: {
                    beginAtZero: true,
                    gridLines: {
                      color: this.$vuetify.theme.isDark ? "#555" : "#ddd",
                    },
                    ticks: {
                      stepSize: 1,
                      maxTicksLimit: 11,
                      fontColor: this.$vuetify.theme.isDark ? "white" : "black",
                    },
                  },
                  x: {
                    gridLines: {
                      color: this.$vuetify.theme.isDark ? "#555" : "#ddd",
                    },
                    ticks: {
                      fontColor: this.$vuetify.theme.isDark ? "white" : "black",
                    },
                  },
                },
              };
              this.lineLoaded = true;
            } else if (chartType === "donut") {
              this.donutData = responseData.donut_data;
              this.totalTaskAmount = this.donutData[0].totalTasks;
              this.openTasks = this.donutData[0].openTasks;
              this.completeTasks = this.donutData[0].completed;
              this.tat = this.donutData[0].tat;
              this.taskPercentage = Math.round(100 * (this.completeTasks / this.totalTaskAmount));
              this.chartDataDonut = {
                labels: ["Open", "Complete"],
                datasets: [
                  {
                    backgroundColor: ["rgb(255, 205, 86)", "rgb(75, 192, 192)"],
                    data: [this.openTasks, this.completeTasks],
                  },
                ],
              };
              this.chartOptionsDonut = {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                  legend: {
                    display: false,
                  },
                },
                cutout: "65%",
                title: {
                  display: true,
                  text: "",
                  fontSize: 25,
                },
                elements: {
                  centerText: true,
                  dark: this.$vuetify.theme.isDark,
                },
              };
              if (this.totalTaskAmount === 0) {
                this.textCenter("No Tasks", 1);
                this.textCenter("Available", 2);
              } else {
                this.textCenter("Completed", 1);
                this.textCenter(this.taskPercentage + "%", 2);
              }
              this.donutLoaded = true;
            }
           else if (chartType === "bar") {
              this.lineXValues = [];
              this.lineYValues = [];
              this.lineData = responseData.line_data;
              for (let i = this.lineData.length - 1; i >= 0; i--) {
                this.lineXValues.push(this.lineData[i].date);
                this.lineYValues.push(this.lineData[i].daily_total);
              }
              this.chartDataBar = {
              
                labels: this.lineXValues,
                datasets: [
                  {
                    label: "New Tasks",
                    data: this.lineYValues,
                    fill: false,
                    borderColor: "#f89554",
                    borderWidth: 1,
                    radius: 5,
                    backgroundColor: '#005ba8',
                  },
                ],
              };
              this.chartOptionsBar = {
                responsive: true,
                maintainAspectRatio: false,
                title: {
                  text: "",
                  fontColor: this.$vuetify.theme.isDark ? "white" : "black",
                  display: true,
                  fontSize: 25,
                },
                legend: {
                  labels: {
                    fontColor: this.$vuetify.theme.isDark ? "white" : "black",
                  },
                },
                tension: 0.4,
                scales: {
                  y: {
                    beginAtZero: true,
                    gridLines: {
                      color: this.$vuetify.theme.isDark ? "#555" : "#ddd",
                    },
                    ticks: {
                      stepSize: 1,
                      maxTicksLimit: 11,
                      fontColor: this.$vuetify.theme.isDark ? "white" : "black",
                    },
                  },
                  x: {
                    gridLines: {
                      color: this.$vuetify.theme.isDark ? "#555" : "#ddd",
                    },
                    ticks: {
                      fontColor: this.$vuetify.theme.isDark ? "white" : "black",
                    },
                  },
                },
              };
              this.lineLoaded = true;
            }
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            var Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          var Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },
    // Center text for donut chart
    textCenter(centerText, lineNum) {
      // Determine what text will be drawn, and remove any previous text
      var id = "beforeDrawNum";
      if (centerText === "No Tasks") {
        id = "beforeDrawNo";
        if (ChartJS.registry.plugins.items.beforeDrawComp) {
          ChartJS.unregister(ChartJS.registry.plugins.items.beforeDrawComp);
          ChartJS.unregister(ChartJS.registry.plugins.items.beforeDrawNum);
        }
      } else if (centerText === "Available") {
        id = "beforeDrawAvailable";
      } else if (centerText === "Completed") {
        id = "beforeDrawComp";
        if (ChartJS.registry.plugins.items.beforeDrawNo) {
          ChartJS.unregister(ChartJS.registry.plugins.items.beforeDrawNo);
          ChartJS.unregister(ChartJS.registry.plugins.items.beforeDrawAvailable);
        }
        if (ChartJS.registry.plugins.items.beforeDrawNum) {
          ChartJS.unregister(ChartJS.registry.plugins.items.beforeDrawNum);
        }
      }
      const beforeDrawPlugin = {
        id: id,
        beforeDraw: function (chart) {
          if (chart.config._config.options.elements !== undefined) {
            if (chart.config._config.options.elements.centerText) {
              var width = chart.width;
              var height = chart.height + (lineNum - 1) * 60 - 30;
              var ctx = chart.ctx;

              ctx.restore();
              ctx.font = "2em sans-serif";
              ctx.textBaseline = "middle";
              ctx.fillStyle = chart.config._config.options.elements.dark ? "white" : "black";

              var text = centerText;
              var textX = Math.round((width - ctx.measureText(text).width) / 2);
              var textY = height / 2;

              ctx.fillText(text, textX, textY);
              ctx.save();
            }
          }
        },
      };
      ChartJS.register(beforeDrawPlugin);
    },
    // Handle card hover
    cardHover(type, card) {
      if (type === "over") {
        this.$vuetify.theme.isDark
          ? document.getElementById(card).setAttribute("style", "background-color: rgb(255,255,255,0.125) !important; transition: 0.4s;")
          : document.getElementById(card).setAttribute("style", "background-color: rgb(0,0,0,0.125) !important; transition: 0.4s;");
      } else if (type === "out") {
        this.$vuetify.theme.isDark
          ? document.getElementById(card).setAttribute("style", "background-color: #383838 !important; transition: 0.4s;")
          : document.getElementById(card).setAttribute("style", "background-color: white !important; transition: 0.4s;");
      }
    },
    // Redirect to pulled hardware queue
    goToQueue(type) {
      this.$router.push({
        name: "pulledHardwareQueue",
        params: { id: btoa(this.userId), type: btoa(type) },
      });
    },
    // On Click of Favorite icon
    addFav() {
      this.showAdd = !this.showAdd;
      AddFavourite.add_remove_Favourite(this.showAdd, this.userId, this.route);
    },
    // To set the action
    actionSetter(action) {
      if (this.actionList !== null && this.actionList.length > 0) {
        let filter = this.actionList.filter((entry) => entry.SystemName === action);
        return filter !== undefined && filter !== null && filter.length == 1 ? true : false;
      } else return false;
    },
  },
  components: {
    breadcrumbComp,
    LineChartGenerator,
    Doughnut,
    Bar
  },
};
